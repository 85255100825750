import { Breadcrumb, BreadcrumbItem } from 'design-react-kit';

export const CustomBreadCrumb = (props) => {

    return (
        <section>
            <Breadcrumb className="breadcrumbTopMargin">
                <BreadcrumbItem active={props.first.active}>
                    <a
                        onClick={props.first.onClick}
                        style={{ cursor: "pointer" }}
                    >
                        {props.first.name}
                    </a>
                    {props.second && <span className='separator'>&gt;</span>}
                </BreadcrumbItem>
                {props.second && <BreadcrumbItem active={props.second.active}>
                    <a
                        onClick={props.second.onClick}
                        style={{ cursor: "pointer" }}
                        className={props.second.active && "activeBreadCrumb"}
                    >
                        {props.second.name}
                    </a>
                    {props.third && <span className='separator'>&gt;</span>}
                </BreadcrumbItem>}
                {props.third &&
                    <BreadcrumbItem active={props.third.active}>
                        <a
                            onClick={props.third.onClick}
                            style={{ cursor: "pointer" }}
                            className={props.third.active && "activeBreadCrumb"}
                        >
                            {props.third.name}
                        </a>
                    </BreadcrumbItem>}
            </Breadcrumb>
        </section>
    )
}




