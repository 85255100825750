import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const getListaReferti = payload => {
  return axios.post(`${BASE_ENDPOINT}/referti/lista`, payload);
};

const download = payload => {
  return axios.post(`${BASE_ENDPOINT}/referti/download`, payload);
};

export default {
    getListaReferti,
    download,
};
