import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const listaComuni = payload => {
    return axios.post(`${BASE_ENDPOINT}/comune/listaComuni`, payload);
};

const listaNazioni = payload => {
    return axios.post(`${BASE_ENDPOINT}/nazione/listaNazioni`, payload);
};

const ricercaCittadini = payload => {
    return axios.post(`${BASE_ENDPOINT}/operatore/paziente`, payload);
};

export default {
    listaComuni,
    ricercaCittadini,
    listaNazioni
};
