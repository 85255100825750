import { useKeycloak } from '@react-keycloak/web';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import { Button, Card, CardBody, CardTagsHeader, CardTitle, Col, Container, GridItem, Header, HeaderContent, Row, Icon, CardCategory, CardFooter } from 'design-react-kit';
import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import refertiService from '../../services/refertiService';
import '../../App.css';
import { wait } from '../common/Loading';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';


function RefertiPage() {
    const { keycloak } = useKeycloak();
    const [listaReferti, setListaReferti] = useState([]);
    const [isOperatore, setIsOperatore] = useState(/*(check if operatore)? true : false*/true);
    const navigate = useNavigate();
    const location = useLocation();


    React.useEffect(() => {
        wait(refertiService.getListaReferti({ codiceFiscale: "test" }))
            .then((resp) => {
                setListaReferti(resp.data.map((data) => {
                    return {
                        ...data, dataEOra: (new Date(data.dataEOra).toLocaleString("it-IT", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        }))
                    }
                }));
            });
    }, []);

    const downloadReferti = () => {
        wait(refertiService.download({ codiceFiscale: "test" }))
            .then((response) => {
                let byteCharacters = atob(response.data.pdf);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    };

    const tornaAOperatore = () => {
        navigate("/operatore", {
            state: {
                datiInseriti: location.state.datiInseriti,
                disableFields: location.state.disableFields,
                nazioneDiNascita: location.state.nazioneDiNascita,
                comuneDiNascita: location.state.comuneDiNascita
            }
        });
    }

    const setInfo = () => {
        if (isOperatore) {
            return <i>{location.state.nome} {location.state.cognome} - {location.state.codiceFiscale}</i>
        } else {
            return <i> {`${keycloak.idTokenParsed.given_name} ${keycloak.idTokenParsed.family_name} - ${keycloak.idTokenParsed.fiscalNumber}`}</i>
        }

    }

    return (
        <div className='docs layout-example'>
            <Container fluid style={{ color: "gray" }}>
                <Row style={{ justifyContent: "center" }}>
                    <Col xs={11}>
                        <Container fluid>
                            <Row>
                                <CustomBreadCrumb
                                    first={{
                                        "name": "Ricerca Assistito",
                                        "onClick": () => {
                                            navigate("/operatore");
                                        },
                                        "active": false
                                    }}
                                    second={{
                                        "name": <b>Ultimi Referti di {setInfo()}</b>,
                                        "onClick": () => { },
                                        "active": true
                                    }}
                                />
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row style={{ justifyContent: "center" }}>
                    <Col xs={11}>
                        <Container fluid>
                            <Row>
                                {listaReferti.map((data) => {
                                    return <Col xs={12} lg={6} xl={4}>
                                        <Card spacing className='card-bg card-big no-after' style={{ height: '100%', borderRadius: "20px", overflow: "hidden" }}>
                                            <CardTitle className='bg-primary text-white m-0 d-flex align-items-center' style={{ height: '80px' }}>
                                                <Container>
                                                    <Row className='w-100 m-0'>
                                                        <Col xs={12} sm={7} className='d-flex align-items-center text-center'>
                                                            <p className='font-weight-bold' style={{ fontSize: "18px", margin: "0" }}>{data.titolo}</p>
                                                        </Col>
                                                        <Col xs={5} className='d-none d-sm-block d-sm-flex align-items-center justify-content-end text-center'>
                                                            <i className='text-sans-serif' style={{ justifyContent: "flex-end", margin: "0px", padding: "0", fontSize: "15px" }}> {data.dataEOra}</i>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </CardTitle>
                                            <Container>
                                                <CardBody style={{ overflowY: 'auto', overflowX: 'hidden', padding: "25px", alignItems: "center" }}>
                                                    <small className='text-muted'>Tipologia</small>
                                                    <h6>{data.tipologia}</h6>
                                                    <small className='text-muted'>Dottore</small>
                                                    <h6>{data.dottore}</h6>
                                                    <small className='text-muted'>Codice Ritiro</small>
                                                    <h6>{data.codiceRitiro}</h6>
                                                </CardBody>
                                                <CardFooter className='d-flex justify-content-end' style={{ borderTop: '0' }}>
                                                    <Button className="buttonPrimary" size='sm' onClick={downloadReferti}>SCARICA</Button>{' '}
                                                </CardFooter>
                                            </Container>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col xs={11}>
                        <Container fluid>
                            <Row classname='d-flex' style={{ margin: "5px 0px" }}>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => tornaAOperatore()}
                                >
                                    INDIETRO
                                </Button>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default RefertiPage;