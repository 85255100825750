import React, {memo, useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import OperatorePage from "./operatore/OperatorePage";
import RefertiPage from "./referti/RefertiPage";
import Page from "./scheleton/page"
import axios from "axios";
import {useKeycloak} from "@react-keycloak/web";

const Root = React.memo(function (props) {

    const [pageRole, setPageRole] = useState('');
    const { keycloak } = useKeycloak();

    useEffect(() => {
        //if(keycloak.realmAccess.roles...) check on role
        setPageRole("operatore");
    }, keycloak);

    axios.interceptors.request.use((request) => {
            if(keycloak.token) {
                request.headers.common.Authorization = `Bearer ${keycloak.token}`;
            }
            return request;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    return (
        <>
            <Routes>
                <Route path="" element={<Navigate replace to={pageRole} />} />
                <Route path="operatore" element={keycloak.authenticated && <OperatorePage />} />
                <Route path="referti" element={keycloak.authenticated && <RefertiPage />} />
            </Routes>
        </>
    );
});

const RootPage = ({ ...props }) => {
    return <Page body={<Root {...props} />} />;
};

export default memo(RootPage);