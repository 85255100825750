import { Alert, Table, Input, Container, Row, Col, Icon, Button, Dropdown, DropdownToggle, DropdownMenu, LinkList, LinkListItem, Select, FormGroup, Label, Tooltip } from 'design-react-kit';
import operatoreService from '../../services/operatoreService'
import React, { useState, useEffect, useRef } from "react";
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import { useLocation, useNavigate } from "react-router-dom";
import { CODICE_FISCALE_REGEX, NOME_COGNOME_REGEX } from "../constants/regexConstants";
import '../../App.css';
import { wait } from '../common/Loading';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function OperatorePage() {
    const location = useLocation();

    const [isValidCodiceFiscale, setIsValidCodiceFiscale] = useState(true);
    const [isValidCognome, setIsValidCognome] = useState(true);
    const [isValidNome, setIsValidNome] = useState(true);
    const [labelDataDiNascita, setLabelDataDiNascita] = useState((location.state) ? (location.state.datiInseriti.dataDiNascita) ? true : false : false);
    const [labelComuneDiNascita, setLabelComuneDiNascita] = useState((location.state) ? (location.state.comuneDiNascita) ? true : false : false);
    const [labelNazioneDiNascita, setLabelNazioneDiNascita] = useState((location.state) ? (location.state.nazioneDiNascita) ? true : false : false);
    const [isSelectNullComuneDiNascita, setIsSelectNullComuneDiNascita] = useState((location.state) ? (location.state.comuneDiNascita) ? false : true : true);
    const [isSelectNullNazioneDiNascita, setIsSelectNullNazioneDiNascita] = useState((location.state) ? (location.state.nazioneDiNascita) ? false : true : true);
    const [tooltipVisualizzaReferti, setTooltipVisualizzaReferti] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const didMount = useRef(false);
    const [disableFields, setDisableFields] = useState({
        "codiceFiscale": (location.state) ? location.state.disableFields.codiceFiscale : false,
        "others": (location.state) ? location.state.disableFields.others : true,
    });
    const [comuniDiNascita, setComuniLista] = useState([]);
    const [nazioniDiNascita, setNazioniLista] = useState([]);
    const [datiInseriti, setDatiInseriti] = useState({
        "codiceFiscale": (location.state) ? location.state.datiInseriti.codiceFiscale : null,
        "cognome": (location.state) ? location.state.datiInseriti.cognome : null,
        "nome": (location.state) ? location.state.datiInseriti.nome : null,
        "dataDiNascita": (location.state) ? location.state.datiInseriti.dataDiNascita : null,
        "comuneDiNascita": (location.state) ? location.state.datiInseriti.comuneDiNascita : null
    });
    const [inputNazioneNascita, setInputNazioneNascita] = useState((location.state) ? (location.state.nazioneDiNascita) ? location.state.nazioneDiNascita : null : null);
    const [listaCittadini, setListaCittadini] = useState([]);
    const [inputComuneNascita, setInputComuneNascita] = useState((location.state) ? (location.state.comuneDiNascita) ? location.state.comuneDiNascita : null : null);


    useEffect(() => {
        if (location.state) {
            if (location.state.datiInseriti.codiceFiscale) {
                document.getElementById("inputCodiceFiscale").value = location.state.datiInseriti.codiceFiscale;
                document.getElementById('inputCodiceFiscale').parentElement.childNodes[1].classList.add('active');
            }
            if (location.state.datiInseriti.cognome) {
                document.getElementById("inputCognome").value = location.state.datiInseriti.cognome;
                document.getElementById('inputCognome').parentElement.childNodes[1].classList.add('active');
            }
            if (location.state.datiInseriti.nome) {
                document.getElementById("inputNome").value = location.state.datiInseriti.nome;
                document.getElementById('inputNome').parentElement.childNodes[1].classList.add('active');
            }
            if (location.state.datiInseriti.dataDiNascita) {
                document.getElementById("inputDataDiNascita").value = location.state.datiInseriti.dataDiNascita;
            }
        }
    }, []);

    useEffect(() => {
        if (inputComuneNascita !== null && inputComuneNascita !== '') {
            document.getElementById("input select comuni di nascita").focus();
        }
        setDatiInseriti({
            "codiceFiscale": datiInseriti.codiceFiscale,
            "cognome": datiInseriti.cognome,
            "nome": datiInseriti.nome,
            "dataDiNascita": datiInseriti.dataDiNascita,
            "comuneDiNascita": (inputComuneNascita) ? inputComuneNascita.value : null
        });
    }, [inputComuneNascita]);

    const validaCampi = () => {
        setIsValidCodiceFiscale(true);
        setIsValidCognome(true);
        setIsValidNome(true);
        setErrorMessage(null);
    };

    useEffect(() => {
        if (didMount.current) {
            if (disableFields.codiceFiscale) {
                let inputCodiceFiscale = document.getElementById("inputCodiceFiscale");
                inputCodiceFiscale.value = "";
                document.getElementById("inputNazioneNascita").childNodes[1].style.background = "white";
                document.getElementById("inputDataDiNascita").style.background = "white";
                inputCodiceFiscale.parentElement.childNodes[1].classList.remove('active');
                setDatiInseriti({
                    "codiceFiscale": null,
                    "cognome": datiInseriti.cognome,
                    "nome": datiInseriti.nome,
                    "dataDiNascita": datiInseriti.dataDiNascita,
                    "comuneDiNascita": datiInseriti.comuneDiNascita
                });
                setIsSelectNullNazioneDiNascita(false);
                setIsSelectNullComuneDiNascita(false);
            }
            if (disableFields.others) {
                let inputCognome = document.getElementById("inputCognome");
                inputCognome.value = "";
                let inputNome = document.getElementById("inputNome");
                inputNome.value = "";
                let inputDataDiNascita = document.getElementById("inputDataDiNascita");
                inputDataDiNascita.value = "";
                document.getElementById("inputComuneNascita").childNodes[1].style.background = "#e6e9f2";
                document.getElementById("inputComuneNascita").childNodes[1].style.opacity = "1";
                document.getElementById("inputNazioneNascita").childNodes[1].style.background = "#e6e9f2";
                document.getElementById("inputNazioneNascita").childNodes[1].style.opacity = "1";
                document.getElementById("inputDataDiNascita").style.background = "#e6e9f2";
                document.getElementById("inputDataDiNascita").style.opacity = "1";
                inputCognome.parentElement.childNodes[1].classList.remove('active');
                inputNome.parentElement.childNodes[1].classList.remove('active');
                setDatiInseriti({
                    "codiceFiscale": datiInseriti.codiceFiscale,
                    "cognome": null,
                    "nome": null,
                    "dataDiNascita": null,
                    "comuneDiNascita": null
                });
                setInputNazioneNascita(null);
                setLabelNazioneDiNascita(false);
                setLabelComuneDiNascita(false);
                setLabelDataDiNascita(false);
                setIsSelectNullComuneDiNascita(true);
                setIsSelectNullNazioneDiNascita(true);
                setInputComuneNascita("");
            }
        } else {
            didMount.current = true;
            if (disableFields.others) {
                document.getElementById("inputComuneNascita").childNodes[1].style.background = "#e6e9f2";
                document.getElementById("inputComuneNascita").childNodes[1].style.opacity = "1";
                document.getElementById("inputNazioneNascita").childNodes[1].style.background = "#e6e9f2";
                document.getElementById("inputNazioneNascita").childNodes[1].style.opacity = "1";
                document.getElementById("inputDataDiNascita").style.background = "#e6e9f2";
                document.getElementById("inputDataDiNascita").style.opacity = "1";
            }
        }
    }, [disableFields])

    const handleChange2 = (inputField, event) => {
        validaCampi();
        setDatiInseriti({
            "codiceFiscale": inputField === "codiceFiscale" ? (event.target.value === '') ? null : event.target.value : datiInseriti.codiceFiscale,
            "cognome": inputField === "cognome" ? (event.target.value === '') ? null : event.target.value : datiInseriti.cognome,
            "nome": inputField === "nome" ? (event.target.value === '') ? null : event.target.value : datiInseriti.nome,
            "dataDiNascita": inputField === "dataDiNascita" ? (event.target.value === '') ? null : event.target.value : datiInseriti.dataDiNascita,
            "comuneDiNascita": inputField === "comuneDiNascita" ? (event.target.value === '') ? null : event.target.value : datiInseriti.comuneDiNascita
        });
    };

    const handleChangeComuneDiNascita = (selectedOption) => {
        validaCampi();
        setInputComuneNascita(selectedOption);
    };

    const handleChangeNazioneDiNascita = (selectedOption) => {
        validaCampi();
        setInputNazioneNascita(selectedOption);
    }

    useEffect(() => {
        if (inputNazioneNascita) {
            if (inputNazioneNascita.label !== 'ITALIA') {
                document.getElementById("inputComuneNascita").childNodes[1].style.background = "#e6e9f2";
                document.getElementById("inputComuneNascita").childNodes[1].style.opacity = "1";
                setLabelNazioneDiNascita(true);
                setLabelComuneDiNascita(false);
                setIsSelectNullComuneDiNascita(true);
                setInputComuneNascita("");
            } else {
                document.getElementById("inputComuneNascita").childNodes[1].style.background = "white";
                document.getElementById("inputComuneNascita").childNodes[1].style.opacity = "1";
                setIsSelectNullComuneDiNascita(false);
            }
        } else {
            document.getElementById("inputComuneNascita").childNodes[1].style.background = "#e6e9f2";
            document.getElementById("inputComuneNascita").childNodes[1].style.opacity = "1";
            setLabelComuneDiNascita(false);
            setIsSelectNullComuneDiNascita(true);
            setInputComuneNascita("");
        }
    }, [inputNazioneNascita]);

    const onInputChange = (inputValue, action, stringa) => {
        validaCampi();
        switch (action.action) {
            case 'input-change':
                {
                    if (stringa === 'comune') {
                        if (inputValue === '') {
                            setComuniLista([]);
                        } else {
                            let payload =
                            {
                                "comuneRicercato": inputValue
                            };
                            document.getElementById("input select comuni di nascita").maxLength = inputValue.length;
                            wait(operatoreService.listaComuni(payload))
                                .then((response) => {
                                    let comuni = [];
                                    comuni = response.data.map(
                                        (comune) => {
                                            return {
                                                "label": comune.nomeComune,
                                                "value": comune.codIstatComune
                                            };
                                        }
                                    );
                                    setComuniLista(comuni);
                                    document.getElementById("input select comuni di nascita").removeAttribute('maxLength');
                                });
                        }
                    } else if (stringa === 'nazione') {
                        if (inputValue === '') {
                            setNazioniLista([]);
                        } else {
                            let payload =
                            {
                                "nazioneRicercata": inputValue
                            };
                            document.getElementById("input select nazioni di nascita").maxLength = inputValue.length;
                            wait(operatoreService.listaNazioni(payload))
                                .then((response) => {
                                    let nazioni = [];
                                    nazioni = response.data.map(
                                        (nazione) => {
                                            return {
                                                "label": nazione.nomeNazione,
                                                "value": nazione.codIstatNazione
                                            };
                                        }
                                    );
                                    setNazioniLista(nazioni);
                                    document.getElementById("input select nazioni di nascita").removeAttribute('maxLength');
                                });
                        }
                    }
                }
        }
    };

    const validateField = (field, setIsFieldValid, validateFunc) => {
        if (field && !validateFunc(field)) {
            setIsFieldValid(false);
            return false;
        }
        else {
            setIsFieldValid(true);
            return true;
        }
    }

    const nomeValidation = (nome) => {
        return !(!nome || NOME_COGNOME_REGEX.test(nome) === false);
    }

    const codiceFiscaleValidation = (codiceFiscale) => {
        return !(!codiceFiscale || CODICE_FISCALE_REGEX.test(codiceFiscale) === false);
    }

    const validateInputFields = () => {
        const codiceFiscale = validateField(datiInseriti.codiceFiscale, setIsValidCodiceFiscale,
            codiceFiscaleValidation);
        const cognome = validateField(datiInseriti.cognome, setIsValidCognome, nomeValidation);
        const nome = validateField(datiInseriti.nome, setIsValidNome, nomeValidation);

        return codiceFiscale && cognome && nome;
    }

    const validateRequiredFields = () => {
        if (disableFields.others && !datiInseriti.codiceFiscale) {
            setErrorMessage("codice fiscale obbligatorio!");
            return false;
        }
        if ((disableFields.codiceFiscale && (!datiInseriti.nome || !datiInseriti.cognome || !datiInseriti.dataDiNascita)) || inputNazioneNascita) {
            let errorMsg = [];
            let msg = "";
            let altroErrore = false;
            if ((!datiInseriti.nome || !datiInseriti.cognome || !datiInseriti.dataDiNascita)) altroErrore = true;
            if (!datiInseriti.nome) errorMsg.push("nome");
            if (!datiInseriti.cognome) errorMsg.push("cognome");
            if (!datiInseriti.dataDiNascita) errorMsg.push("data di nascita");
            if (inputNazioneNascita) {
                if (inputNazioneNascita.label === "ITALIA" && (inputComuneNascita == null || inputComuneNascita === '')) {
                    errorMsg.push("comune di nascita");
                } else if (!altroErrore) return true;
            }
            errorMsg.map((elem, index) => {
                msg += elem;
                msg += (index < errorMsg.length - 1) ? (index === errorMsg.length - 2) ? " e " : ", " : "";
            })
            msg += (errorMsg.length > 1) ? " obbligatori!" : " obbligatorio!";
            setErrorMessage(msg);
            return false;
        }
        setErrorMessage(null);
        return true;
    }

    const ricercaCittadini = () => {
        setErrorMessage(null);
        if (!validateInputFields()) {
            return;
        }
        setListaCittadini([]);
        if (validateRequiredFields()) {
            let payload = datiInseriti;
            if (inputNazioneNascita) {
                if (!(inputNazioneNascita.label === 'ITALIA')) {
                    payload.comuneDiNascita = '999' + inputNazioneNascita.value;
                }
            }
            wait(operatoreService.ricercaCittadini(datiInseriti))
                .then((response) => {
                    let tmp = [];
                    response.data.map((cittadino, index) => {
                        tmp.push({
                            "id": index,
                            "codiceFiscale": cittadino.codiceFiscale,
                            "cognome": cittadino.cognome,
                            "nome": cittadino.nome,
                            "nascita": cittadino.dataDiNascita,
                            "comuneNascita": cittadino.comuneDiNascita,
                            "telefono": cittadino.telefono,
                            "azioni": false
                        });
                    });
                    setListaCittadini(tmp);
                })
                .catch((error) => {
                    setErrorMessage(error.response.data);
                });
        }
    };

    const vaiAReferti = (date) => {
        navigate("/referti", {
            state: {
                codiceFiscale: date.codiceFiscale,
                cognome: date.cognome,
                nome: date.nome,
                datiInseriti: datiInseriti,
                nazioneDiNascita: inputNazioneNascita,
                comuneDiNascita: inputComuneNascita,
                disableFields: disableFields
            }
        });
    };

    const customStylesComuniNascita = {
        valueContainer: (styles, state) => ({
            ...styles,
            background: '#e6e9f2',
            opacity: '1'
        })
    };

    const customStylesNazioniNascita = {
        valueContainer: (styles, state) => ({
            ...styles,
            background: '#e6e9f2',
            opacity: '1'
        }),
        menuPortal: base => ({
            ...base,
            zIndex: 9999
        })
    };

    const handleDisabledFields = (field) => {
        setDisableFields({
            "codiceFiscale": (field === "codiceFiscale") ? false : true,
            "others": (field === "others") ? false : true
        })
        validaCampi();
    }

    const formatDate = (dataDiNascita) => {
        const formattedDate = dataDiNascita.slice(0, 10).split("-");
        return formattedDate[2] + "-" + formattedDate[1] + "-" + formattedDate[0];
    }

    const isDisabledComuneNascita = () => {
        if (disableFields.others) {
            return true;
        }
        if (inputNazioneNascita) {
            if (inputNazioneNascita.label === "ITALIA") {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <Container>
                <CustomBreadCrumb
                    first={{
                        "name": "Ricerca Assistito",
                        "onClick": () => {
                        },
                        "active": false
                    }}
                />
                <Col xs={12}>
                    <Container style={{ marginTop: "5%" }}>
                        <Row>
                            <Col xs={1}>
                                <FormGroup check style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Input defaultChecked={(disableFields.others) ? true : false} name='gruppo1' type='radio' id='radio1' onChange={() => { handleDisabledFields("codiceFiscale") }} />
                                    <Label check htmlFor='radio1' />
                                </FormGroup>
                            </Col>
                            <Col xs={11}>
                                <Input infoText={isValidCodiceFiscale ? false : "Codice fiscale non valido"} invalid={!isValidCodiceFiscale}
                                    type='text' className='clickableLabel' label={'Codice fiscale' + ((disableFields.codiceFiscale) ? '' : '*')} id='inputCodiceFiscale'
                                    disabled={disableFields.codiceFiscale} onChange={(event) => handleChange2("codiceFiscale", event)}
                                    onClick={() => { document.getElementById('inputCodiceFiscale').parentElement.childNodes[1].classList.add('active'); }}
                                    onFocus={() => { document.getElementById('inputCodiceFiscale').parentElement.childNodes[1].classList.add('active'); }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1}>
                                <FormGroup check style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Input defaultChecked={(disableFields.codiceFiscale) ? true : false} name='gruppo1' type='radio' id='radio2' onChange={() => { handleDisabledFields("others") }} />
                                    <Label check htmlFor='radio2' />
                                </FormGroup>
                            </Col>
                            <Col xs={11}>
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <Input infoText={isValidCognome ? false : "Cognome non valido"} invalid={!isValidCognome}
                                            type='text' className='clickableLabel' label={'Cognome' + ((disableFields.others) ? '' : '*')} id='inputCognome'
                                            disabled={disableFields.others} onChange={(event) => handleChange2("cognome", event)}
                                            onClick={() => { document.getElementById('inputCognome').parentElement.childNodes[1].classList.add('active'); }}
                                            onFocus={() => { document.getElementById('inputCognome').parentElement.childNodes[1].classList.add('active'); }}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Input infoText={isValidNome ? false : "Nome non valido"} invalid={!isValidNome}
                                            type='text' className='clickableLabel' label={'Nome' + ((disableFields.others) ? '' : '*')} id='inputNome'
                                            disabled={disableFields.others} onChange={(event) => handleChange2("nome", event)}
                                            onClick={() => { document.getElementById('inputNome').parentElement.childNodes[1].classList.add('active'); }}
                                            onFocus={() => { document.getElementById('inputNome').parentElement.childNodes[1].classList.add('active'); }}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div class="form-group">
                                            <label for="dateStandard"
                                                class={(labelDataDiNascita) ? "active" : null}
                                                onClick={() => {
                                                    if (disableFields.codiceFiscale) {
                                                        setLabelDataDiNascita(true);
                                                        document.getElementById("inputDataDiNascita").focus();
                                                    }
                                                }}
                                                style={{ cursor: (disableFields.others) ? "not-allowed" : "text" }}
                                            >{'Data di nascita' + ((disableFields.others) ? '' : '*')}</label>
                                            <input class="textbox-n" id="inputDataDiNascita"
                                                type={(!labelDataDiNascita) ? "text" : "date"}
                                                onChange={(event) => handleChange2("dataDiNascita", event)}
                                                onBlur={() => { if (datiInseriti.dataDiNascita == null || datiInseriti.dataDiNascita == "") setLabelDataDiNascita(false) }}
                                                onFocus={() => { setLabelDataDiNascita(true) }}
                                                disabled={disableFields.others}
                                                style={{ cursor: (disableFields.others) ? "not-allowed" : "text" }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={12} lg={6}>
                                        <div class="form-group" style={{ cursor: ((disableFields.others) ? "not-allowed" : "text") }}>
                                            <label htmlFor='selectExampleClassic'
                                                class={(labelNazioneDiNascita) ? "active" : null}
                                                onClick={() => {
                                                    if (disableFields.codiceFiscale) {
                                                        setLabelNazioneDiNascita(true);
                                                        document.getElementById("input select nazioni di nascita").focus();
                                                    }
                                                }}
                                                style={{ cursor: (disableFields.others) ? "not-allowed" : "text" }}
                                            >Nazione di nascita</label>
                                            <Select
                                                id={"inputNazioneNascita"}
                                                inputId={"input select nazioni di nascita"}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                onChange={handleChangeNazioneDiNascita}
                                                placeholder=""
                                                onBlur={() => {
                                                    if (inputNazioneNascita == null || inputNazioneNascita == "") setLabelNazioneDiNascita(false);
                                                    setNazioniLista([]);
                                                }}
                                                onFocus={() => { setLabelNazioneDiNascita(true) }}
                                                onInputChange={(inputValue, action) => onInputChange(inputValue, action, "nazione")}
                                                options={nazioniDiNascita}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                                                openMenuOnFocus={true}
                                                styles={customStylesNazioniNascita}
                                                isDisabled={disableFields.others}
                                                value={(isSelectNullNazioneDiNascita) ? "null" : inputNazioneNascita}
                                                captureMenuScroll
                                                maxMenuHeight={100}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div class="form-group" style={{ cursor: ((isDisabledComuneNascita()) ? "not-allowed" : "text") }}>
                                            <label htmlFor='selectExampleClassic'
                                                class={(labelComuneDiNascita) ? "active" : null}
                                                onClick={() => {
                                                    if (disableFields.codiceFiscale) {
                                                        if (!isDisabledComuneNascita()) {
                                                            setLabelComuneDiNascita(true);
                                                            document.getElementById("input select comuni di nascita").focus();
                                                        }
                                                    }
                                                }}
                                                style={{ cursor: (isDisabledComuneNascita()) ? "not-allowed" : "text" }}
                                            >{"Comune di nascita" + ((inputNazioneNascita) ? (inputNazioneNascita.label === 'ITALIA') ? "*" : "" : "")}</label>
                                            <Select
                                                id={"inputComuneNascita"}
                                                inputId={"input select comuni di nascita"}
                                                isClearable={true}
                                                onChange={handleChangeComuneDiNascita}
                                                placeholder=""
                                                onBlur={() => {
                                                    if (datiInseriti.comuneDiNascita == null || datiInseriti.comuneDiNascita === "") {
                                                        setLabelComuneDiNascita(false);
                                                    }
                                                    setComuniLista([]);
                                                }}
                                                onFocus={() => { setLabelComuneDiNascita(true) }}
                                                onInputChange={(inputValue, action) => onInputChange(inputValue, action, "comune")}
                                                options={comuniDiNascita}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                                                openMenuOnFocus={true}
                                                styles={customStylesComuniNascita}
                                                isDisabled={isDisabledComuneNascita()}
                                                value={(isSelectNullComuneDiNascita) ? "null" : inputComuneNascita}
                                                captureMenuScroll
                                                maxMenuHeight={100}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    <Container style={{ marginBottom: "3%" }}>
                        <Row className="d-flex justify-content-end">
                            <Col xs={12} lg={8}>
                                <Row className="alignElementsCenterAndEnd" style={{ display: "flex", justifyContent: "end" }}>
                                    <Col className="alignElementsCenterAndEnd" xs={12} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                                        <Button className="buttonPrimary" style={{ width: "100%" }} onClick={ricercaCittadini}>CERCA</Button>{' '}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    {
                        listaCittadini.length > 0 &&
                        <Container>
                            <Row style={{ justifyContent: "center" }}>
                                <Col>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Codice Fiscale</th>
                                                <th scope='col'>Cognome</th>
                                                <th scope='col'>Nome</th>
                                                <th scope='col'>Data di Nascita</th>
                                                <th scope='col'>Comune di Nascita</th>
                                                <th scope='col'>Telefono</th>
                                                <th scope='col'>Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listaCittadini.map((data, index) => {
                                                return (

                                                    <tr>
                                                        <td>{data.codiceFiscale}</td>
                                                        <td>{data.cognome}</td>
                                                        <td>{data.nome}</td>
                                                        <td>{formatDate(data.nascita)}</td>
                                                        <td>{data.comuneNascita}</td>
                                                        <td>{data.telefono}</td>
                                                        <td
                                                            onClick={() => { vaiAReferti(data) }}
                                                        >
                                                            <span
                                                                style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
                                                                id={"visualizza_" + index}
                                                            >
                                                                <Icon
                                                                    icon="it-password-visible"
                                                                />
                                                            </span>
                                                            <Tooltip
                                                                placement='top'
                                                                target={"visualizza_" + index}
                                                                isOpen={tooltipVisualizzaReferti}
                                                                toggle={() => setTooltipVisualizzaReferti(!tooltipVisualizzaReferti)}
                                                            >
                                                                Visualizza referti
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    }
                </Col>
                {(!(errorMessage === null)) && <Alert color='danger'>{errorMessage}</Alert>}
            </Container>
        </>
    )
}

export default OperatorePage;