import { BrowserRouter as Router } from "react-router-dom";
import CustomFooter from "./components/common/CustomFooter";
import CustomHeader from "./components/common/CustomHeader";
import RootPage from "./components/RootPage";
import packageInfo from "../package.json";
import Loading from './components/common/Loading';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

function App() {

    return (
        <ReactKeycloakProvider authClient={keycloak} onReady={() => { keycloak.updateToken(5) }} initOptions={{ onLoad: 'login-required', pkceMethod: 'S256'}} >
            <Router basename={packageInfo.homepage}>
                <Loading />
                <CustomHeader />
                <RootPage />
                <CustomFooter />
            </Router>
        </ReactKeycloakProvider>
    );
}

export default App;
