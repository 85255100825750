
import React, { memo } from "react";
import Body from "./body";
import PropTypes from "prop-types";

function Page(props) {
  const { body, backgroundColor } = props;
  let style = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };
  if (backgroundColor) {
    style = { ...style, backgroundImage: backgroundColor };
  } else {
    style = { ...style, backgroundColor: "white" };
  }
  return (
        <div style={style}>
          {/* <Header /> */}
          <Body content={body} />
          {/* <Footer /> */}
        </div>
  );
}

Page.propTypes = {
  body: PropTypes.element.isRequired,
};

export default React.memo(Page);

