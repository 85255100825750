import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import { Button, Collapse, Header, HeaderBrand, HeaderContent, HeaderRightZone, Headers, HeaderSocialsZone, HeaderToggler, Icon, Nav, NavItem, NavLink } from 'design-react-kit';
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import logo from '../../img/regione-sicilia-logo.svg';
import {useKeycloak} from "@react-keycloak/web";
import { UserAvatar } from '../custom/UserAvatar';

function CustomHeader() {
    const [isOperatore, setIsOperatore] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        //if(keycloak.realmAccess.roles...) check on role
        setIsOperatore(true);
    }, keycloak);

    return (
        <Headers>
          <div className="it-nav-wrapper">

        <Header
            small
            theme=""
            type="center"
        >
            <HeaderContent>
                <HeaderBrand iconName={logo}>
                    <h2>
                        Ritiro Referti
                    </h2>
                    <h3>
                        Regione Sicilia
                    </h3>
                </HeaderBrand>
                <HeaderRightZone>
                    {keycloak.authenticated && <UserAvatar/>}
                </HeaderRightZone>
            </HeaderContent>
        </Header>
        <Header theme="" type="navbar">
        <HeaderContent
         expand="lg"
         megamenu
        >
          <HeaderToggler
           aria-controls="nav1"
           aria-expanded="false"
           aria-label="Toggle navigation"
           onClick={function noRefCheck(){}}
          >
            <Icon icon="it-burger" />
          </HeaderToggler>
          <Collapse
            header
            navbar
            onOverlayClick={function noRefCheck(){}}
            >
            <div className="menu-wrapper">
                <Nav navbar>
                { !isOperatore?
                (<NavItem>
                    <NavLink
                    as={Link} to="/referti"
                    active
                    style={{cursor: "default"}}
                    >
                    <span>
                        Ultimi Referti{' '}
                    </span>
                    </NavLink>
                </NavItem>) : null
                }
                { isOperatore?
                (<NavItem>
                    <NavLink
                    active
                    style={{cursor: "default"}}
                    >
                    <span>
                    Оperatore
                    </span>
                    </NavLink>
                </NavItem>) : null
                }
                </Nav>
            </div>
          </Collapse>
        </HeaderContent>
        </Header>
  </div>

        </Headers>
    );
}

export default CustomHeader;