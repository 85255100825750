
const EMAIL_REGEX =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const NOME_COGNOME_REGEX = /^[A-zÀ-ú ]{0,30}$/;
const CODICE_FISCALE_REGEX = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
const NOME_UTENTE_REGEX = /^[A-zÀ-ú0-9#_$^\"\'\-+=!*()@%&.,:;/ ]{0,30}$/;

export {
    EMAIL_REGEX,
    NOME_UTENTE_REGEX,
    CODICE_FISCALE_REGEX,
    NOME_COGNOME_REGEX,
};
