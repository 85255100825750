import { Spinner } from 'design-react-kit';
import React, { useState, useEffect } from "react";

function Loading() {
    const [loading, setLoading] = useState(false);

    const enableSpinner = () => setLoading(true);

    const disableSpinner = () => setLoading(false);

    useEffect(() => {   
        window.addEventListener('loading start', enableSpinner);
        window.addEventListener('loading end', disableSpinner);
        return () => {      
            window.removeEventListener('loading start', enableSpinner);    
            window.removeEventListener('loading end', disableSpinner);
        };
    }, []);

    document.body.onmousedown = (event) => {
        if(loading){
            event.preventDefault();
        }
    };

    document.body.onkeydown = (event) => {
        if(loading){
            event.preventDefault();
        }
    };

    return(
        <>
        {(loading) && 
            <div style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', width: '100%', height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: '1000'}}>
                <div className='col-12' style={{width: '5%', height: '5%', position: 'fixed', top: '50%', left:'50%', transform: 'translate(-50%, -50%)'}}>
                    <p className='mb-3'>
                    </p>
                    <Spinner double active />
                </div>
            </div>
        }
        </>
    );
}

export function wait(fn) {
    window.dispatchEvent(new Event('loading start'));
    return Promise.resolve(fn).finally(() => window.dispatchEvent(new Event('loading end')));
}

export default Loading;